<template>
	<div>
		<v-row v-for="(option, id) in answerOptions" :key="id">
			<v-col cols="8">
				<v-checkbox
					:value="option.value"
					v-model="selected"
					:hide-details="true"
					:multiple="multiple"
					:label="option.text"
					:disabled="disabled"
				>
				</v-checkbox>
			</v-col>
			<v-col class="pt-6">
				<slot name="pollResults" v-bind:answer="option.value"></slot>
			</v-col>
		</v-row>
	</div>
</template>

<style lang="less">
@import "../../../../src/assets/css/survey/answerOptions.less";
</style>

<script type="text/javascript">
export default {
	name: "Checkboxes",
	props: {
		id: {},
		value: {},
		disabled: {},
		multiple: { type: Boolean, default: false },
	},
	data: () => {
		return {
			selected: [],
		};
	},
	computed: {
		answerOptions() {
			return (
				this.$store.state.questionTemplates.data[this.id].answer_options ||
				{}
			);
		},
	},
	watch: {
		selected(v) {
			if (v) {
				this.$emit("update", v);
			}
		},
		value: {
			immediate: true,
			handler() {
				this.selected = this.value;
			},
		},
	},
};
//
</script>
"
